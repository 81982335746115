@import "~@bcgov/bootstrap-theme/dist/scss/bootstrap-theme";

.layout-app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  .layout-head {
    flex: 0;
    width: 100%;
  }
  .layout-container {
    flex: 1;
    width: 100%;
    padding-top: 0;
  }
  .layout-footer {
    width: 100%;
  }
}

// fix date-picker calendar appearing behind other elements
.react-datepicker-popper {
  z-index: 2147483647 !important;
  transform: translate3d(263px, -96px, 0px) !important;
}
